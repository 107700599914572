import * as FeatherIcon from 'react-feather';
import { IMenu } from '../../interfaces/menu';
import { PermissaoCodigo } from '../enums/permissao-codigo.enum';
import { ProgramaCodigo } from '../enums/programa-codigo.enum';
import { RoleCodigo } from '../enums/role-codigo.enum';

export const AdminClienteMenus: IMenu = {
  roles: [RoleCodigo.ClienteAdmin],
  name: 'Admin Cliente',
  children: [
    {
      programaCod: ProgramaCodigo.CadernoCampo,
      name: 'Configurações',
      icon: FeatherIcon.Settings,
      children: [
        {
          programaCod: ProgramaCodigo.Configuracoes,
          name: 'Configurações',
          path: '/configuracoes/listar',
          component: 'listarConfiguracoes',
          children: [
            {
              permissaoCod: PermissaoCodigo.Alterar,
              path: '/configuracoes/editar-valor/:id',
              component: 'configuracoesCadastro',
            },
            {
              permissaoCod: PermissaoCodigo.Acesso,
              path: '/configuracoes/listar',
              component: 'configuracoesListar',
            },
          ],
        },
        {
          programaCod: ProgramaCodigo.ModelosRelatorios,
          name: 'Modelos de Relatórios',
          path: '/modelos-relatorios/listar',
          component: 'listarModeloRelatorio',
          children: [
            {
              permissaoCod: PermissaoCodigo.Acesso,
              path: '/modelos-relatorios/listar',
              component: 'listarModeloRelatorio',
            },
            {
              permissaoCod: PermissaoCodigo.Incluir,
              path: '/modelos-relatorios/criar',
              component: 'modeloRelatorio',
            },
            {
              permissaoCod: PermissaoCodigo.Alterar,
              path: '/modelos-relatorios/editar/:id',
              component: 'modeloRelatorio',
            },
            {
              permissaoCod: PermissaoCodigo.Acesso,
              path: '/modelos-relatorios/visualizar/:id',
              component: 'modeloRelatorio',
            },
            {
              permissaoCod: PermissaoCodigo.Incluir,
              path: '/modelos-relatorios/replicar/:id',
              component: 'modeloRelatorio',
            },
          ],
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.Empresas,
      name: 'Empresas',
      path: '/empresas/listar',
      icon: FeatherIcon.Briefcase,
      component: 'empresasLista',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/empresas/listar',
          component: 'empresasLista',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/empresas/criar',
          component: 'empresasCadastro',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/empresas/editar/:id',
          component: 'empresasCadastro',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/empresas/visualizar/:id',
          component: 'empresasCadastro',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.PerfilDeAcesso,
      name: 'Perfis De Acesso',
      path: '/perfil-acesso/listar',
      icon: FeatherIcon.UserX,
      component: 'listaPerfilAcesso',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/perfil-acesso/listar',
          component: 'listaPerfilAcesso',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/perfil-acesso/criar',
          component: 'perfilAcesso',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/perfil-acesso/editar/:id',
          component: 'perfilAcesso',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/perfil-acesso/visualizar/:id',
          component: 'perfilAcesso',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.Usuarios,
      name: 'Usuários',
      path: '/usuarios/listar',
      icon: FeatherIcon.Users,
      component: 'listarUsuarios',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/usuarios/listar',
          component: 'listarUsuarios',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/usuarios/criar',
          component: 'usuarios',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/usuarios/editar/:id',
          component: 'usuarios',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/usuarios/visualizar/:id',
          component: 'usuarios',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/usuarios/replicar/:id',
          component: 'usuarios',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.RelatoriosLog,
      icon: FeatherIcon.FileText,
      name: 'Logs',
      path: '/pages/relatorios-logs',
      component: 'relatoriosLogs',
    },
  ],
};
