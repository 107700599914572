import { IMenu } from '../../interfaces/menu';
import { PermissaoCodigo } from '../enums/permissao-codigo.enum';
import { ProgramaCodigo } from '../enums/programa-codigo.enum';
import { RoleCodigo } from '../enums/role-codigo.enum';

export const FrutaMenus: IMenu = {
  roles: [RoleCodigo.ClienteUser, RoleCodigo.ClienteAdmin],
  name: 'Frutas',
  children: [
    {
      programaCod: ProgramaCodigo.EntradaFrutas,
      name: 'Entrada de Frutas',
      path: '/entrada-frutas/listar',
      component: 'listarEntradaFrutas',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/entrada-frutas/listar',
          component: 'listarEntradaFrutas',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/entrada-frutas/criar',
          component: 'entradaFrutas',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/entrada-frutas/editar/:id',
          component: 'entradaFrutas',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/entrada-frutas/visualizar/:id',
          component: 'entradaFrutas',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.EtiquetasEntradaFrutas,
      name: 'Registro de Etiquetas Entrada de Frutas',
      path: '/registro-etiquetas-entrada-frutas/listar',
      component: 'litarRegistroEtiquetaEntradaFruta',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/registro-etiquetas-entrada-frutas/listar',
          component: 'litarRegistroEtiquetaEntradaFruta',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/registro-etiquetas-entrada-frutas/criar',
          component: 'registroEtiquetaEntradaFruta',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/registro-etiquetas-entrada-frutas/editar/:id',
          component: 'registroEtiquetaEntradaFruta',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/registro-etiquetas-entrada-frutas/visualizar/:id',
          component: 'registroEtiquetaEntradaFruta',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/registro-etiquetas-entrada-frutas/etiqueta-personalizada/:etiquetasEntradaFrutasId',
          component: 'etiquetaPersonalizadaRegistroEtiquetaEntradaFruta',
        },
      ],
    },
  ],
};
