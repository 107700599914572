// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import jwt_decode from 'jwt-decode';

import { fetchJSON } from '../../../helpers/api';

import {
  LOGOUT_USER,
  REGISTER_USER,
  FORGET_PASSWORD,
  OBTER_ORGANIZACOES_AUTORIZADAS,
  SELECIONAR_ORGANIZACAO,
  OBTER_MODULOS_PERMISSOES,
  OBTER_EMPRESAS_AUTORIZADAS,
  SELECIONAR_EMPRESA,
  PRIMEIRO_ACESSO_TROCAR_SENHA,
  OBTER_SEGMENTOS_AUTORIZADOS,
  SELECIONAR_SEGMENTO,
  OBTER_PARAMETROS_REDUX,
} from './constants';

import {
  registerUserSuccess,
  registerUserFailed,
  forgetPasswordSuccess,
  forgetPasswordFailed,
  setarOrganizacoesAutorizadas,
  updateUser,
  setarEmpresasAutorizadas,
  obterModulosPermissoes,
  buscarEmpresasAutorizadas,
  setarProgramasPermissoes,
  selecionarOrganizacao,
  selecionarEmpresa,
  logoutUser,
  selecionarSegmento,
  setarSegmentosAutorizados,
  setarParametrosRedux,
  obterParametrosRedux,
} from './actions';
import {
  obterEmpresasAutorizadas,
  obterOrganizacoesAutorizadas,
  obterParametrosReduxAsync,
  obterProgramasPermissoesAsync,
  obterSegmentosAutorizadosAsync,
  selecionarEmpresaAsync,
  selecionarOrganizacaoAsync,
  selecionarSegmentoAsync,
  trocarSenhaPrimeiroAcessoAsync,
} from '../requests';
import { setLogoutUser, setUserPermissions } from '../../../helpers/authUtils';
import { Alert } from '../../../helpers/Alert';
import { changeLayoutLogo, changeLayoutThemeColors } from '../../../redux/actions';
import { selecionarSolucaoId } from './actions';

function* logout() {
  try {
    yield setLogoutUser();
    window.location.href = '/account/login';
  } catch (error) {}
}

function* register({ payload: { fullname, email, password } }) {
  const options = {
    body: JSON.stringify({ fullname, email, password }),
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };

  try {
    const response = yield call(fetchJSON, '/users/register', options);
    yield put(registerUserSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error;
    }
    yield put(registerUserFailed(message));
  }
}

function* forgetPassword({ payload: { login } }) {
  const options = {
    body: JSON.stringify({ login }),
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };

  try {
    const response = yield call(fetchJSON, '/users/password-reset', options);
    yield put(forgetPasswordSuccess(response.message));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error;
    }
    yield put(forgetPasswordFailed(message));
  }
}

function* selecionarOrganizacaoAutorizada({ payload }) {
  try {
    const organizacao = payload;

    if (!organizacao.revenda) {
      yield Alert({ type: 'confirm', title: 'A organização selecionada não possui uma revenda' });
    }

    const { data } = yield call(selecionarOrganizacaoAsync, organizacao.id);
    const decodedToken = jwt_decode(data.access_token);

    yield put(
      updateUser({
        roles: decodedToken.roles,
        organizacao: { id: organizacao.id, nome: organizacao.nome },
        revenda: { id: organizacao.revenda.id, nome: organizacao.revenda.nome },
      }),
    );

    yield put(selecionarSolucaoId(organizacao.solucao?.id));

    const theme = {
      corFundoPagina: organizacao.solucao?.corFundoPagina,

      corSecundaria: organizacao.solucao?.corSecundaria,
      corPrimaria: organizacao.solucao?.corPrimaria,
      corFonteMenu: organizacao.solucao?.corFonteMenu,

      corBarraTitulo: organizacao.solucao?.corBarraTitulo,
      corFonteTitulo: organizacao.solucao?.corFonteTitulo,

      corBotaoPrimario: organizacao.solucao?.corBotaoPrimario,
      corTextoBotaoPrimario: organizacao.solucao?.corTextoBotaoPrimario,
      corBotaoSecundario: organizacao.solucao?.corBotaoSecundario,
      corTextoBotaoSecundario: organizacao.solucao?.corTextoBotaoSecundario,
      corBotaoEditar: organizacao.solucao?.corBotaoEditar,
      corTextoBotaoEditar: organizacao.solucao?.corTextoBotaoEditar,
      corBotaoDeletar: organizacao.solucao?.corBotaoDeletar,
      corTextoBotaoDeletar: organizacao.solucao?.corTextoBotaoDeletar,
      corBotaoVisualizar: organizacao.solucao?.corBotaoVisualizar,
      corTextoBotaoVisualizar: organizacao.solucao?.corTextoBotaoVisualizar,

      corBarraFiltro: organizacao.solucao?.corBarraFiltro,
      corFormularioConfiguracoes: organizacao.solucao?.corFormularioConfiguracoes,
      corFonteLabel: organizacao.solucao?.corFonteLabel,
      corInput: organizacao.solucao?.corInput,
      corFonteInput: organizacao.solucao?.corFonteInput,
      corFormularioListagem: organizacao.solucao?.corFormularioListagem,

      corFonteColunasGrid: organizacao.solucao?.corFonteColunasGrid,
      corFonteRegistrosGrid: organizacao.solucao?.corFonteRegistrosGrid,
      corFundoTituloGrid: organizacao.solucao?.corFundoTituloGrid,
      corPrimariaLinhaGrid: organizacao.solucao?.corPrimariaLinhaGrid,
      corSecundariaLinhaGrid: organizacao.solucao?.corSecundariaLinhaGrid,
      corLinhaTituloAVencerGrid: organizacao.solucao?.corLinhaTituloAVencerGrid,
      corTextoTituloAVencerGrid: organizacao.solucao?.corTextoTituloAVencerGrid,
      corLinhaTituloVencidoGrid: organizacao.solucao?.corLinhaTituloVencidoGrid,
      corTextoTituloVencidoGrid: organizacao.solucao?.corTextoTituloVencidoGrid,
      corLinhaTituloFechadoGrid: organizacao.solucao?.corLinhaTituloFechadoGrid,
      corTextoTituloFechadoGrid: organizacao.solucao?.corTextoTituloFechadoGrid,

      corFormularioCadastro: organizacao.solucao?.corFormularioCadastro,
      corFonteLabelCadastro: organizacao.solucao?.corFonteLabelCadastro,
      corInputCadastro: organizacao.solucao?.corInputCadastro,
      corFonteInputCadastro: organizacao.solucao?.corFonteInputCadastro,
      corBarraRelatorio: organizacao.solucao?.corBarraRelatorio,
      corGuias: organizacao.solucao?.corGuias,
      corTituloGuias: organizacao.solucao?.corTituloGuias,
    };

    yield put(changeLayoutThemeColors(theme));

    const logo = {
      lg: organizacao.solucao?.logoGrande,
      sm: organizacao.solucao?.logoPequeno,
    };

    yield put(changeLayoutLogo(logo));

    yield put(buscarEmpresasAutorizadas());
  } catch (error) {
    yield Alert({
      title: error.response?.data?.message || 'Ocorreu um erro ao selecionar a organização',
      type: 'error',
    });
    yield put(logoutUser());
  }
}

function* selecionarEmpresaAutorizada({ payload }) {
  try {
    const empresa = payload;
    yield call(selecionarEmpresaAsync, empresa.id);
    yield put(updateUser({ empresa: { id: empresa.id, razaoSocial: empresa.razaoSocial } }));
    yield put(obterModulosPermissoes());
    yield put(obterParametrosRedux());
  } catch (error) {
    yield Alert({
      title: 'Ocorreu um erro ao acessar a empresa',
      text: error.response.data.message || 'Verifique a situação de sua licença',
      showCancelButton: false,
      type: 'confirm',
    });
    yield put(logoutUser());
  }
}

function* selecionarSegmentoAutorizado({ payload }) {
  try {
    const seg = payload;
    yield call(selecionarSegmentoAsync, seg.id);
    yield put(updateUser({ segmento: { id: seg.id, nome: seg.nome } }));
    yield put(obterModulosPermissoes());
  } catch (error) {
    yield Alert({
      title: 'Ocorreu um erro ao acessar o segmento',
      text: error.response.data.message,
      showCancelButton: false,
      type: 'confirm',
    });
    yield put(logoutUser());
  }
}

function* obterModulosPermissoesAutorizados() {
  try {
    const { data } = yield call(obterProgramasPermissoesAsync);
    if (!data?.result?.length) {
      yield Alert({
        type: 'confirm',
        title: 'Seu usuário não possui acesso a nenhum programa',
        text: 'Por favor, solicite a liberação e tente novamente',
      });
      yield put(logoutUser());
      return;
    }

    setUserPermissions(data.result);

    yield put(setarProgramasPermissoes(data.result));
    window.location.href = '/dashboard';
  } catch (error) {
    yield Alert({
      title: 'Ocorreu um erro no seu acesso ao sistema',
      text: error.response.data.message,
      showCancelButton: false,
      type: 'confirm',
    });
    yield put(logoutUser());
  }
}

function* buscarParametrosRedux() {
  try {
    const { data } = yield call(obterParametrosReduxAsync);
    yield put(setarParametrosRedux(data));
  } catch (error) {
    yield Alert({
      title: 'Ocorreu um erro ao obter os parâmetros',
      text: error.response.data.message,
      showCancelButton: false,
      type: 'confirm',
    });
    yield put(logoutUser());
  }
}

function* listarOrganizacoesAutorizadas() {
  try {
    const { data } = yield call(obterOrganizacoesAutorizadas);

    if (data.length === 0) {
      yield Alert({
        type: 'confirm',
        title: 'Seu usuário não possui organizações ativas',
        text: 'Por favor, verifique com o setor responsável',
      });
      yield put(logoutUser());
      return;
    }

    yield put(setarOrganizacoesAutorizadas(data));
    // se tiver apenas uma organizacao já seleciona automaticamente
    if (data.length === 1) {
      yield put(selecionarOrganizacao(data[0]));
    }

    yield put(setarEmpresasAutorizadas([]));
  } catch (err) {
    yield Alert({
      title: err.response?.data?.message || 'Ocorreu um erro ao buscar sua organização',
      type: 'error',
    });
    yield put(logoutUser());
  }
}

function* listarEmpresasAutorizadas() {
  try {
    const { data } = yield call(obterEmpresasAutorizadas);
    if (data.length === 0) {
      yield Alert({
        type: 'confirm',
        title: 'Esta organização não possui nenhuma empresa ativa',
        text: 'Por favor, verifique com o setor responsável',
      });
      yield put(logoutUser());
      return;
    }

    yield put(setarEmpresasAutorizadas(data));

    //se tiver apenas uma organizacao já selecionar
    if (data.length === 1) {
      yield put(selecionarEmpresa(data[0]));
    }
  } catch (err) {
    alert(err.message);
  }
}

function* listarSegmentosAutorizados() {
  try {
    const { data } = yield call(obterSegmentosAutorizadosAsync);
    if (data.length === 0) {
      yield Alert({
        type: 'confirm',
        title: 'Seu usuário não possui acesso a nenhum segmento',
        text: 'Por favor, solicite a liberação e tente novamente',
      });
      yield put(logoutUser());
      return;
    }

    //se tiver apenas uma organizacao já selecionar
    if (data.length === 1) {
      yield put(selecionarSegmento(data[0]));
    }

    yield put(setarSegmentosAutorizados(data));
  } catch (err) {
    alert(err.message);
  }
}

function* trocarSenhaPrimeiroAcesso({ payload }) {
  try {
    const { status } = yield call(trocarSenhaPrimeiroAcessoAsync, payload);

    if (status === 200) {
      yield put(
        updateUser({
          primeiroAcesso: false,
        }),
      );
    }
  } catch (err) {
    alert(err.message);
  }
}

export function* watchObterModulosPermissoes() {
  yield takeEvery(OBTER_MODULOS_PERMISSOES, obterModulosPermissoesAutorizados);
}

export function* watchPrimeiroAcesso() {
  yield takeEvery(PRIMEIRO_ACESSO_TROCAR_SENHA, trocarSenhaPrimeiroAcesso);
}

export function* watchListarEmpresasAutorizadas() {
  yield takeEvery(OBTER_EMPRESAS_AUTORIZADAS, listarEmpresasAutorizadas);
}

export function* watchListarSegmentosAutorizados() {
  yield takeEvery(OBTER_SEGMENTOS_AUTORIZADOS, listarSegmentosAutorizados);
}

export function* watchListarOrganizacoesAutorizadas() {
  yield takeEvery(OBTER_ORGANIZACOES_AUTORIZADAS, listarOrganizacoesAutorizadas);
}

export function* watchSelecionarOrganizacao() {
  yield takeEvery(SELECIONAR_ORGANIZACAO, selecionarOrganizacaoAutorizada);
}

export function* watchSelecionarEmpresa() {
  yield takeEvery(SELECIONAR_EMPRESA, selecionarEmpresaAutorizada);
}

export function* watchSelecionarSegmento() {
  yield takeEvery(SELECIONAR_SEGMENTO, selecionarSegmentoAutorizado);
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, register);
}

export function* watchForgetPassword() {
  yield takeEvery(FORGET_PASSWORD, forgetPassword);
}

export function* watchObterParametrosRedux() {
  yield takeEvery(OBTER_PARAMETROS_REDUX, buscarParametrosRedux);
}

function* authSaga() {
  yield all([
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgetPassword),
    fork(watchListarOrganizacoesAutorizadas),
    fork(watchObterModulosPermissoes),
    fork(watchSelecionarOrganizacao),
    fork(watchListarEmpresasAutorizadas),
    fork(watchListarSegmentosAutorizados),
    fork(watchSelecionarEmpresa),
    fork(watchSelecionarSegmento),
    fork(watchPrimeiroAcesso),
    fork(watchObterParametrosRedux),
  ]);
}

export default authSaga;
