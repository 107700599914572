import styled from 'styled-components';

export const ThemeProvider = styled.div`
  #sidebar-menu > ul > li > ul {
    padding-left: 20px;
  }

  background-color: ${({ theme }) => theme.corFundoPagina} !important;

  footer {
    background-color: ${({ theme }) => theme.corFundoPagina} !important;
  }

  .left-side-menu {
    background: ${({ theme }) => theme.corPrimaria} !important;
  }

  .navbar-custom {
    background: ${({ theme }) => theme.corPrimaria} !important;
  }

  .media-body {
    color: ${({ theme }) => theme.corFonteMenu} !important;
  }

  .user-profile .pro-user-name {
    color: ${({ theme }) => theme.corFonteMenu} !important;
  }

  #sidebar-menu > ul > li > a {
    color: ${({ theme }) => theme.corFonteMenu} !important;
    & {
      svg {
        color: ${({ theme }) => theme.corFonteMenu} !important;
      }
    }
  }

  .user-profile .dropdown-toggle svg {
    color: ${({ theme }) => theme.corFonteMenu} !important;
  }

  #sidebar-menu > ul > li > a:hover,
  #sidebar-menu > ul > li > a:focus,
  #sidebar-menu > ul > li > a:active,
  #sidebar-menu > ul > .mm-active > a {
    background: ${({ theme }) => theme.corSecundaria}0D !important;
    color: ${({ theme }) => theme.corSecundaria} !important;
    border-left-color: ${({ theme }) => theme.corSecundaria} !important;
    & a svg {
      color: ${({ theme }) => theme.corSecundaria} !important;
    }
  }

  .nav-second-level li a,
  .side-nav-thrid-level li a {
    color: ${({ theme }) => theme.corFonteMenu} !important;

    :hover,
    :focus,
    :active {
      color: ${({ theme }) => theme.corSecundaria} !important;
    }
  }

  #sidebar-menu .side-nav-item .mm-active {
    > a {
      color: ${({ theme }) => theme.corSecundaria} !important;
    }
  }

  .btn-primary {
    background-color: ${({ theme }) => theme.corBotaoPrimario} !important;
    border-color: ${({ theme }) => theme.corBotaoPrimario} !important;
    color: ${({ theme }) => theme.corTextoBotaoPrimario} !important;
  }

  .btn-secondary {
    background-color: ${({ theme }) => theme.corBotaoSecundario} !important;
    border-color: ${({ theme }) => theme.corBotaoSecundario} !important;
    color: ${({ theme }) => theme.corTextoBotaoSecundario} !important;
  }

  .btn-info {
    background-color: ${({ theme }) => theme.corBotaoEditar} !important;
    border-color: ${({ theme }) => theme.corBotaoEditar} !important;
    color: ${({ theme }) => theme.corTextoBotaoEditar} !important;
  }

  .btn-danger {
    background-color: ${({ theme }) => theme.corBotaoDeletar} !important;
    border-color: ${({ theme }) => theme.corBotaoDeletar} !important;
    color: ${({ theme }) => theme.corTextoBotaoDeletar} !important;
  }

  .btn-success {
    background-color: ${({ theme }) => theme.corBotaoVisualizar} !important;
    border-color: ${({ theme }) => theme.corBotaoVisualizar} !important;
    color: ${({ theme }) => theme.corTextoBotaoVisualizar} !important;
  }

  .table.table-striped thead {
    background-color: ${({ theme }) => theme.corFundoTituloGrid} !important;
    color: ${({ theme }) => theme.corFonteColunasGrid} !important;
  }

  .table.table-striped thead th {
    border-color: ${({ theme }) => theme.corFonteColunasGrid}0D !important;
  }

  .table.table-striped tbody tr:nth-of-type(even) {
    background-color: ${({ theme }) => theme.corSecundariaLinhaGrid} !important;
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: ${({ theme }) => theme.corPrimariaLinhaGrid} !important;
  }

  .table-striped tbody td {
    color: ${({ theme }) => theme.corFonteRegistrosGrid} !important;
  }

  .table.table-striped tbody tr.titulo-vencido {
    background-color: ${({ theme }) => theme.corLinhaTituloVencidoGrid} !important;

    td {
      color: ${({ theme }) => theme.corTextoTituloVencidoGrid} !important;
    }
  }

  .table.table-striped tbody tr.titulo-fechado {
    background-color: ${({ theme }) => theme.corLinhaTituloFechadoGrid} !important;

    td {
      color: ${({ theme }) => theme.corTextoTituloFechadoGrid} !important;
    }
  }

  .table.table-striped tbody tr.titulo-a-vencer {
    background-color: ${({ theme }) => theme.corLinhaTituloAVencerGrid} !important;

    td {
      color: ${({ theme }) => theme.corTextoTituloAVencerGrid} !important;
    }
  }

  .card-listagem {
    position: relative;
    background-color: ${({ theme }) => theme.corFormularioListagem} !important;
  }

  .card-titulo-pagina {
    background-color: ${({ theme }) => theme.corBarraTitulo} !important;
    color: ${({ theme }) => theme.corFonteTitulo} !important;

    h2 {
      color: ${({ theme }) => theme.corFonteTitulo} !important;
    }
  }

  label {
    color: ${({ theme }) => theme.corFonteLabel} !important;
  }

  .form-control {
    background-color: ${({ theme }) => theme.corInput} !important;
    color: ${({ theme }) => theme.corFonteInput} !important;
    border-color: ${({ theme }) => theme.corFonteInput}26 !important;
  }

  .filtro {
    background-color: ${({ theme }) => theme.corBarraFiltro} !important;
  }

  .filtro-avancado {
    background-color: ${({ theme }) => theme.corFormularioConfiguracoes} !important;
  }

  .filtro-relatorio {
    background-color: ${({ theme }) => theme.corBarraRelatorio} !important;
  }

  .custom-control-input ~ .custom-control-label::before {
    border-color: ${({ theme }) => theme.corBotaoPrimario} !important;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    background: ${({ theme }) => theme.corBotaoPrimario} !important;
  }

  .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background: ${({ theme }) => theme.corBotaoPrimario}80 !important;
  }

  .cor-paginacao-ativo {
    background: ${({ theme }) => theme.corBotaoPrimario} !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .guia-cadastro {
    .titulo-guia-cadastro {
      background-color: ${({ theme }) => theme.corGuias} !important;

      h5 {
        color: ${({ theme }) => theme.corTituloGuias} !important;
      }
    }

    .formulario-guia-cadastro {
      background-color: ${({ theme }) => theme.corFormularioCadastro} !important;

      .form-control {
        background-color: ${({ theme }) => theme.corInputCadastro} !important;
        color: ${({ theme }) => theme.corFonteInputCadastro} !important;
        border-color: ${({ theme }) => theme.corFonteInputCadastro}26 !important;
      }

      .form-control:focus {
        border: 2px solid ${({ theme }) => theme.corBotaoPrimario} !important;
      }

      label {
        color: ${({ theme }) => theme.corFonteLabelCadastro} !important;
      }
    }
  }

  .domper-select__menu {
    z-index: 2;
  }

  .custom-file-label::after {
    content: 'Selecionar' !important;
  }
`;
